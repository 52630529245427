import React, { useContext, useState } from "react";
import Card from "../Card";
import UserContext from "../UserContext";
import { Link } from "react-router-dom";
import FilterItem from "../FilterItem";






function ProductList({path}) {
  const {setfilterOffcanvas,products,Filters, handleFilters, iPhoneModelFilterList, AndroidAccessoriesFilterList} = useContext(UserContext);
  const [sort, setSort] = useState({value: "desc", label: 'Price'});


  function handleSortOrderChange(option) {
    if (option.label === 'Low to High') {
      setSort({ value: 'asc', label: 'Low to High' });
    } else if (option.label === 'High to Low') {
      setSort({ value: 'desc', label: 'High to Low' });
    } else {
      setSort({ value: 'desc', label: 'Price' });
    }
  }

  const sortedProducts = products.sort((a, b) => {
    if (sort.value === "asc") {
      return a.price - b.price;
    } else {
      return b.price - a.price;
    }
  });


  return(
    <>
    {path === "iphone" ? "iphone" : "android-accessories"}
    <section className="container choices d-flex flex-column">
      
      <div className="p-3 d-flex flex-column flex-md-row align-items-center justify-content-between">
        <div className="my-2 my-md-0 d-flex align-items-center">
          <h4 className="m-0">
            <Link to="/" className="shortcut-link text-secondary">
              Home
            </Link>{' '}
            / {path === 'iphone' ? "iPhone": "Android & Accessories"}
          </h4>
        </div>
        <div className="my-2 my-md-0 d-lg-none d-flex align-items-center">
          <a href="#" onClick={()=>setfilterOffcanvas(true)}>
            <i className="fa-solid fa-bars text-dark"></i>
          </a>
          <p className="ms-2 mb-0">FILTER</p>
        </div>
        <div className="my-2 my-md-0 d-flex align-items-center">
          <p className="mb-0 me-2">Sort Price:</p>
          <div className="dropdown">
            <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              {sort.label}
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#" onClick={() => handleSortOrderChange({ value: 'asc', label: 'Low to High' }) }>
                  Low to High
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#" onClick={() => handleSortOrderChange({ value: 'desc', label: 'High to Low' })}>
                  High to Low
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="d-flex mb-4">
        <div className="p-3 d-none d-lg-block category-iphone col-3">
          <h4>Filter</h4>
          {path === 'iphone' ?
          <FilterItem handleFilters={filters => handleFilters(filters)} data={iPhoneModelFilterList} isChecked={Filters}/> 
          :
          <FilterItem handleFilters={filters => handleFilters(filters)} data={AndroidAccessoriesFilterList} isChecked={Filters}/>
          }
        </div>
        <div className="items col-12 col-lg-9 d-flex flex-wrap">
          {sortedProducts
            .filter((item) => Filters.length === 0 || Filters.includes(item.model) || Filters.includes(item.type))
            .map((item) => {
              if (item.type.toLowerCase() === "apple" && path === "iphone") {
                return (
                  <Card
                    key={item.id}
                    id={item.id}
                    src={item.src}
                    type={item.type}
                    brand={item.brand}
                    model={item.model}
                    storage={item.storage}
                    color={item.color}
                    oldprice={item.oldprice}
                    price={item.price}
                    quantity={item.quantity}
                  />
                );
              } else if (item.type.toLowerCase() !== "apple" && path !== "iphone") {
                return (
                  <Card
                    key={item.id}
                    id={item.id}
                    src={item.src}
                    type={item.type}
                    brand={item.brand}
                    model={item.model}
                    storage={item.storage}
                    color={item.color}
                    oldprice={item.oldprice}
                    price={item.price}
                    quantity={item.quantity}
                  />
                );
              }
            })}
          
        </div>
      </div>
      {/* <div className="my-4 d-flex justify-content-center">
        <ul className="pagination">
          <li className="page-item iphone active">
            <a href="iphone.html" className="page-link">
              1
            </a>
          </li>
          <li className="page-item iphone">
            <a href="iphone2.html" className="page-link">
              2
            </a>
          </li>

          <li className="page-item iphone">
            <a href="iphone2.html" className="page-link">
              Next
            </a>
          </li>
        </ul>
      </div> */}
    </section>
  </>
  );
}

export default ProductList;
