import React from "react";
import MenuHead from "./MenuHead";
import { Link } from "react-router-dom";


function Menu() {
    return(
        <div className="offcanvas offcanvas-start" id="navmenu">
          <MenuHead menu='Menu'/>
          <div className="offcanvas-body" id="side-menu">
            <Link to="/dashboard" className="p-3 btn d-flex">
              Home 
            </Link>
            <Link to='/sales' className="p-3 btn d-flex ">
              Sales </Link>
            <a
              data-bs-toggle="collapse"
              data-bs-target=""
              className="p-3 btn d-flex justify-content-between align-items-center"
            >
              Inventory
            </a>
          </div>
      </div>

    )
}


export default Menu;