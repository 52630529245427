import React, { useContext } from 'react';
import { Offcanvas } from 'react-bootstrap';
import FilterItem from '../FilterItem';
import UserContext from '../UserContext';
import OffcanvasHeader from './OffcanvasHeader';


function OffFilterItem({ path }) {
  const {AndroidAccessoriesFilterList,Filters,iPhoneModelFilterList,handleFilters, filterOffcanvas, setfilterOffcanvas}= useContext(UserContext)
  return (
    <Offcanvas onHide={()=>setfilterOffcanvas(false)} show={filterOffcanvas} className="offcanvas offcanvas-start cart-added" id="filter">
      <OffcanvasHeader menu="Filter" onClose={setfilterOffcanvas} />
      <Offcanvas.Body className="offcanvas-body">
      {path === 'iphone' ?
          <FilterItem handleFilters={filters => handleFilters(filters)} data={iPhoneModelFilterList} isChecked={Filters}/> 
          :
          <FilterItem handleFilters={filters => handleFilters(filters)} data={AndroidAccessoriesFilterList} isChecked={Filters}/>
          }
        </Offcanvas.Body>
    </Offcanvas>
  );
}

export default OffFilterItem;
