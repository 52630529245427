import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from './assets/logo.png';
import UserContext from './UserContext';

const Navbar = ({ unit }) => {
  const {setcartOffcanvas, setsideMenuOffcanvas, sethowToModal} = useContext(UserContext);
  return (
    <nav className="navbar navbar-expand-lg fixed-top">
      <div className="container">
        <Link className="navbar-brand order-2 order-lg-1" to="/">
          <img src={logo} alt="" />
        </Link>
        <a
          href="#"
          className="ms-3 ms-sm-0 d-block d-lg-none order-1"
          onClick={()=> setsideMenuOffcanvas(true)}
        >
          <i className="fa-solid fa-bars "></i>
        </a>
        <a
          href="#"
          className="d-block d-lg-none order-3 cart-icon"
          onClick={()=> setcartOffcanvas(true)}
        >
          
          <i className="me-3 me-sm-0 fa-solid fa-cart-shopping "></i>
          <span>{unit}</span>
        </a>
        <div
          id="menu"
          className="collapse order-lg-2 justify-content-end navbar-collapse"
        >
          <ul className="navbar-nav align-items-center">
            <li className="nav-item">
              <Link to="/" className="mx-2 nav-link">
                Home
              </Link>
            </li>
            <li className="nav-item">
              {/* <a
                href="#"
                data-bs-target="#how-to-buy"
                data-bs-toggle="modal"
                className="mx-2 nav-link "
              >
                How to Order
              </a> */}
              <button
                onClick={()=>sethowToModal(true)}
                className="mx-2 nav-link nav-btn"
              >
                How to Order
              </button>
            </li>
            <li className="nav-item">
              <Link to="/iphone" className="mx-2 nav-link ">
                iPhone
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/android-accessories" className="mx-2 nav-link ">
                Android & Accessories
              </Link>
            </li>
            <li className="nav-item ">
              <a
                role="button"
                className="mx-2 nav-link cart-icon"
                onClick={()=> setcartOffcanvas(true)}
              >
                <i className="fa-solid fa-cart-shopping"></i>
                <span>{unit}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
