import React, { useContext } from "react";
import { useLocation, Link} from 'react-router-dom';
import UserContext from "../UserContext";

const Product = () => {
    const {addItemToCart, setcartOffcanvas} = useContext(UserContext);
    const location = useLocation();
    const data = location.state?.data;

    return(
        <>
        <section className="product-details container">
        <div>
            <div className="d-flex flex-column flex-lg-row">
            <div className="d-flex col-lg-5 col-12 justify-content-center">
                <img src={data.src} alt="" />
                <div className="mini-img">
                <div className="my-2 border rounded-1">
                    <a href="#">
                        <img src={data.src} className="rounded-3" alt=""/>
                    </a>
                </div>
                

            </div>
            </div>
            <div className="mt-5 mt-lg-0 ms-lg-5 d-flex flex-column col-12 col-lg-5">
                <h5 className="mb-4">
                <Link to='/' className="shortcut-link text-secondary">Home</Link>&nbsp;/&nbsp;
                <Link to={data.type.toLowerCase() === 'apple' ? "/iphone": "/android-accessories"} className="shortcut-link text-secondary">{data.type.toLowerCase() === 'apple' ? "iPhone": "Android & Accessories"}</Link>&nbsp;
                /&nbsp;{data.brand} {data.model} {data.storage} {data.color}
                </h5>
                <h3 className="mb-4">{data.brand} {data.model} {data.storage} {data.color}</h3>
                <h5 className="mb-4 fw-bold fs-2">{data.price.toLocaleString("en-US", { style: 'currency', currency: 'PHP' })}</h5>
                <div className="mb-4 d-flex align-items-center">
                <button type="button" onClick={() => {addItemToCart(data);setcartOffcanvas(true);}} className="px-3 py-2 btn">
                    Add to cart
                </button>
                </div>
                <div>
                <h4>Product Details</h4>
                <p>
                    Nokia 6 features a Gorilla Glass protected, in-cell 5.5-inch
                    2.5D display with a resolution of 1080 x 1920 pixels. The
                    Snapdragon 430 chipset powers the phone, containing an octa-core
                    CPU and the Adreno 505 GPU. 3GB of RAM is inside along with 32GB
                    of native storage. The 16MP rear camera carries an f/2.0
                    aperture and PDAF laser auto focusing. In front is an 8MP
                    camera, also sporting an f/2.0 aperture, for selfies and video
                    chats.
                </p>
                </div>
            </div>
            </div>
        
        
        </div>
        </section>
    
        <section className="my-4 p-0 specs container border rounded-2">
        <div className="specs-details">
            <div className="p-2 specs-title sticky-top">
            <h5>display</h5>
            </div>
            <table className="table table-striped table-hover">
            <thead>
                &nbsp;
            </thead>
            <tbody>
                <tr>
                <th scope="row">Size:</th>
                <td>6.1 inches</td>
                </tr>
                <tr>
                <th scope="row">Resolution:</th>
                <td>2532 x 1170 pixels, 19.5:9 ratio, 457 PPI</td>
                </tr>
                <tr>
                <th scope="row">Refresh rate:</th>
                <td colSpan="2">60Hz</td>
                </tr>
                <tr>
                <th scope="row">Screen-to-body:</th>
                <td colSpan="2">87.24 %</td>
                </tr>
                <tr>
                <th scope="row">Features:</th>
                <td colSpan="2">
                    HDR support, Oleophobic coating, Scratch-resistant glass
                    (Ceramic Shield), Ambient light sensor, Proximity sensor
                </td>
                </tr>
            </tbody>
            </table>
        </div>
        <div className="specs-details">
            <div className="p-2 specs-title sticky-top">
            <h5>hardware</h5>
            </div>
            <table className="table table-striped table-hover">
            <thead>
                &nbsp;
            </thead>
            <tbody>
                <tr>
                <th scope="row">System chip:</th>
                <td>Apple A15 Bionic (5 nm)</td>
                </tr>
                <tr>
                <th scope="row">Processor:</th>
                <td>Hexa-core, 64-bit</td>
                </tr>

                <tr>
                <th scope="row">RAM:</th>
                <td colSpan="2">4GB</td>
                </tr>
                <tr>
                <th scope="row">Internal storage:</th>
                <td colSpan="2">128GB</td>
                </tr>
                <tr>
                <th scope="row">OS:</th>
                <td colSpan="2">iOS (15.x)</td>
                </tr>
            </tbody>
            </table>
        </div>
        <div className="specs-details">
            <div className="p-2 specs-title sticky-top">
            <h5>battery</h5>
            </div>
            <table className="table table-striped table-hover">
            <thead>
                &nbsp;
            </thead>
            <tbody>
                <tr>
                <th scope="row">Capacity:</th>
                <td>3227 mAh</td>
                </tr>
                <tr>
                <th scope="row">Charging:</th>
                <td>
                    USB Power Delivery, Qi wireless charging, MagSafe wireless
                    charging
                </td>
                </tr>
                <tr>
                <th scope="row">Max charge speed:</th>
                <td colSpan="2">Wired: 20.0W; Wireless: 15.0W</td>
                </tr>
            </tbody>
            </table>
        </div>
        <div className="specs-details">
            <div className="p-2 specs-title">
            <h5>camera</h5>
            </div>
            <table className="table table-striped table-hover">
            <thead>
                &nbsp;
            </thead>
            <tbody>
                <tr>
                <th scope="row">Rear:</th>
                <td>Dual camera</td>
                </tr>
                <tr>
                <th scope="row">Main camera:</th>
                <td>12 MP (OIS, PDAF)</td>
                </tr>
                <tr>
                <th scope="row">Second camera:</th>
                <td colSpan="2">12 MP (Ultra-wide)</td>
                </tr>
                <tr>
                <th scope="row">Video recording:</th>
                <td colSpan="2">
                    3840x2160 (4K UHD) (60 fps), 1920x1080 (Full HD) (240 fps),
                    1280x720 (HD) (30 fps)
                </td>
                </tr>
                <tr>
                <th scope="row">Front:</th>
                <td colSpan="2">
                    12 MP (Time-of-Flight (ToF), EIS, HDR, Slow-motion videos)
                </td>
                </tr>
            </tbody>
            </table>
        </div>
    </section>
    </>
    )
}


export default Product;