import React from 'react';
import logo from '../assets/logo.png';
import { Offcanvas, CloseButton } from 'react-bootstrap';

function OffcanvasHeader({ menu, onClose }) {
  return (
    <>
      <Offcanvas.Header className="offcanvas-header">
        <img src={logo} alt="" className="rounded-circle" />
        <h5 className="m-0">{menu}</h5>
        <CloseButton variant="white" onClick={()=>onClose(false)} aria-label="Close" />
        {/* <button
          type="button"
          data-bs-dismiss="offcanvas"
          className="btn-close btn-close-white"
        ></button> */}
      </Offcanvas.Header>
    </>
  );
}

export default OffcanvasHeader;
