import React, { useContext, useEffect } from 'react';
import { Offcanvas } from 'react-bootstrap';
import UserContext from '../UserContext';
import OffcanvasHeader from './OffcanvasHeader';


function Cart({ cartItems, setCartItems, handleChangeQuantity }) {
const {price, setPrice, setcartOffcanvas,cartOffcanvas, setcheckoutModal} = useContext(UserContext);

  const handleRemove = (id) => {
    const arr = cartItems.filter((item) => item.model.concat(item.id) !== id);
    
    setCartItems(arr);
    handlePrice();
  };

  const handlePrice = () => {
    let ans = 0;
    cartItems.map((item) => (ans += item.quantity * parseFloat(item.price)));
    setPrice(ans);
  };

  useEffect(() => {
    handlePrice();
  });


  

  return (
    <Offcanvas onHide={()=>setcartOffcanvas(false)} placement='end' show={cartOffcanvas} className="offcanvas offcanvas-end cart-added " id="cart">
      {/* <Offcanvas.Header className="offcanvas-header" closeButton>

        <h5 className="m-0">Menu</h5>
      
      </Offcanvas.Header> */}
       <OffcanvasHeader menu="Cart" onClose={setcartOffcanvas}/>
      <Offcanvas.Body className="offcanvas-body">
        <div>
          {cartItems.length == 0 ? (
            <div className="d-flex justify-content-center">
              <h3>You have no items in cart.</h3>
            </div>
          ) : (
            <>
              {cartItems.map((item) => (
                <div
                  className="p-3 mt-2 border d-flex justify-content-between"
                  key={item.model.concat(item.id)}
                >
                  <div className="d-flex">
                    <img src={item.src} alt="" className="cart-img me-2" />
                    <div className="d-flex flex-column justify-content-center">
                      <h6>
                        {item.brand} {item.model} {item.color}
                      </h6>
                      <p>
                        {item.price.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'PHP',
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-center flex-nowrap">
                    <div className="input-group quantity mb-3">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => handleChangeQuantity(item, 1)}
                      >
                        +
                      </button>
                      <input
                        type="number"
                        value={item.quantity}
                        className="form-control"
                        readOnly
                      />
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => handleChangeQuantity(item, -1)}
                      >
                        -
                      </button>
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-delete btn-danger"
                        onClick={() => handleRemove(item.model.concat(item.id))}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              ))}

              <div className="mt-3 d-flex flex-column align-items-end justify-content-end">
                <p>
                  <strong>Total:</strong>
                  {price.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'PHP',
                  })}
                </p>
                <div>
                  <button
                    type="button"
                    className="btn btn-block btn-warning"
                    onClick={() => {setcartOffcanvas(false);setcheckoutModal(true);}}
                  >
                    Checkout
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default Cart;
