import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
  return (
    <nav className="navbar navbar-expand-lg fixed-top">
      <div className="container">
        {/* <Link className="navbar-brand order-2 order-lg-1" to='/'
            ><img src={logo} alt=""
          /></Link> */}
        <a
          href="#"
          className="ms-3 ms-sm-0 d-block d-lg-none order-1"
          data-bs-toggle="offcanvas"
          data-bs-target="#navmenu"
        >
          <i className="fa-solid fa-bars "></i>
        </a>
        <div
          id="menu"
          className="collapse order-lg-2 justify-content-end navbar-collapse"
        >
          <ul className="navbar-nav align-items-center">
            <li className="nav-item">
              <Link to="/admin/dashboard" className="mx-2 nav-link ">
                Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/admin/dashboard/inventory" className="mx-2 nav-link ">
                Inventory
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/admin/dashboard/orders" className="mx-2 nav-link ">
                Order
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/admin/dashboard/reservations"
                className="mx-2 nav-link "
              >
                Reservations
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
