import React, { useContext } from 'react';
import UserContext from "./UserContext";

function Card(props) {
  const {addItemToCart, handleSeeMoreButton, setcartOffcanvas} = useContext(UserContext);
    return (
        <div className="mb-3 card col-6 col-md-4 col-lg-3 border-0" 
        key={props.id}>
            <div className="card-header text-white">
              <img
                src={props.src}
                className="imgprops"
                alt=""
              />
              <div className="buy-now p-2 text-center rounded-top">
                <a
                  href="#"
                  onClick={() =>{addItemToCart(props); setcartOffcanvas(true);}}
                  >Buy Now</a>
              </div>
            </div>
            <div className="card-body">
              <h6 className="card-title text-center">{props.type}</h6>
              <p className="card-text">
                {props.brand} {props.model} <br />
                {props.storage && props.color ?  `${props.storage} ${props.color}`: '' }
                
              </p>
              <p className="price">

                <span className="text-decoration-line-through old-price">
                    {props.oldprice ? props.oldprice.toLocaleString("en-US",{style: 'currency', currency: 'PHP'}) : ''}
                </span>&nbsp;

                {props.price.toLocaleString("en-US",{style: 'currency', currency: 'PHP'})}
              </p>
            </div>
            <div className="card-footer text-center">
              <button type="button" onClick={()=> handleSeeMoreButton(props.id, props.model)} className="btn px-4">See More</button>
            </div>
          </div>
    )
}

export default Card;
