import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from './UserContext';

const Checkout = () => {
  const { setCustomerDetails, CustomerDetails, orderForm, setCustomer} = useContext(UserContext);
  const navigate = useNavigate();



  
  const handleChange = (e) => {
    setCustomerDetails({ ...CustomerDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !CustomerDetails.fname &&
      !CustomerDetails.lname &&
      !CustomerDetails.number &&
      !CustomerDetails.email ||
      (orderForm &&
        (!CustomerDetails.address ||
          !CustomerDetails.city ||
          !CustomerDetails.region ||
          !CustomerDetails.postalcode))
    ) {
      return;
    }
    setCustomer(CustomerDetails)
    navigate('/summary');
  };
  return (
    <section className="user-details container">
      <form onSubmit={handleSubmit} className="p-md-5 p-2">
        <h1 className="text-center">Order Form</h1>
        <div className="mb-3">
          <label htmlFor="fname" className="form-label">
            Name
          </label>
          <div className="d-flex flex-column flex-lg-row">
            <div className="pe-lg-2 col-12 col-lg-6">
              <input
                onChange={handleChange}
                type="text"
                id="fname"
                className="form-control form-control"
                name="fname"
                placeholder="First name"
                required
              />
            </div>
            <div className="ps-lg-2 col-12 col-lg-6">
              <input
                onChange={handleChange}
                type="text"
                id="lname"
                className="form-control form-control"
                name="lname"
                placeholder="Last name"
                required
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-lg-row mb-3">
          <div className="pe-lg-2 col-12 col-lg-6">
            <label htmlFor="number" className="form-label">
              Contact
            </label>
            <input
              onChange={handleChange}
              type="number"
              id="number"
              className="form-control form-control"
              name="number"
              placeholder="Contact no."
              required
            />
          </div>
          <div className="ps-lg-2 col-12 col-lg-6">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              onChange={handleChange}
              type="email"
              id="email"
              className="form-control form-control"
              name="email"
              placeholder="Email"
              required
            />
          </div>
        </div>

       
        {orderForm ? 
        <>
          <div>
            <label htmlFor="address" className="form-label">Shipping Address</label>
          </div>
          <input
            onChange={handleChange} type="text" className="form-control" id="address" name="address" placeholder="Street Address"/>
          <input
            onChange={handleChange} type="text" className="form-control" id="address" name="address" placeholder="Street Address Line 2"/>

          <div className="d-flex flex-column flex-lg-row">
            <div className="pe-lg-2 col-12 col-lg-6">
              <input
                onChange={handleChange} type="text" id="city" className="form-control form-control" name="city" placeholder="City"/>
            </div>
          <div className="ps-lg-2 col-12 col-lg-6">
              <input
                onChange={handleChange} type="text" id="region" className="form-control form-control" name="region" placeholder="Region"/>
          </div>
          </div>
          <div className="d-flex">
            <div className="me-lg-2 col-12 col-lg-6 pe-lg-2">
              <input
                onChange={handleChange} type="text" id="postalcode" className="form-control form-control" name="postalcode" placeholder="Postal / Zip Code"/>
            </div>
          </div>
          
          </>
          :null
        }
        
        <div className="mb-3">
          <label htmlFor="notes" className="form-label">
            Note to seller:
          </label>

          <textarea
            onChange={handleChange}
            name="notes"
            id="notes"
            className="form-control"
            rows="3"
            placeholder="Type your notes here"
          ></textarea>
        </div>
        <div className="d-flex justify-content-end">
          <button type="submit" className="px-3 btn" >
            Submit
          </button>
        </div>
      </form>
    </section>
  );
};

export default Checkout;
