import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorView from './components/ErrorView';
import Hero from './components/Home/Hero';
import Features from './components/Home/Features';
import Categories from './components/Home/Categories';
import OffFilterItem from './components/Offcanvas/OffFilterItem';
import Product from './components/ProductPage/Product';
import Login from './components/AdminPage/LoginPage/Login';
import AdminPanel from './components/AdminPage/DashBoard/AdminPanel';
import Checkout from './components/Checkout';
import AdminPanelContent from './components/AdminPage/DashBoard/AdminPanelContent';
import InventoryContent from './components/AdminPage/Inventory/InventoryContent';
import ProductList from './components/ProductPage/ProductList';
import Summary from './components/Summary';
import Receipt from './components/Receipt';



const router = createBrowserRouter([
  {
      path: '/',
      element: <App/>,
      errorElement: <ErrorView/>,
      children: [
          {
            path: '/',
            element: <><Hero/><Categories/><Features/></>,        
          },
          {
            path: '/iphone',
            element: <><ProductList path={"iphone"}/><OffFilterItem path={"iphone"}/></>,
          },
          {
            path: '/iphone/:id',
            element:<><Product/></>
          },
          {
            path:'/android-accessories',
            element: <><ProductList path={"android-accessories"}/><OffFilterItem path={"android-accessories"}/>,</>
          },
          {
            path:'/android-accessories/:id',
            element: <><Product/></>
          },
          {
            path: '/checkout',
            element: <><Checkout/></>,
          },
          {
            path: '/product',
            element: <></>,
          },
          {
            path:'/summary',
            element:<Summary/>
          },
          {
            path:'/receipt',
            element:<Receipt/>
          },
      ]
  },

  {
    path: '/admin',
    element: <Login />,
    errorElement: <ErrorView />,
  },
  {
    path: '/admin/dashboard',
    element: <AdminPanel />,
    errorElement: <ErrorView />,
    children: [
      {
        path: '/admin/dashboard',
        element: <AdminPanelContent />,
      },
      {
        path: '/admin/dashboard/inventory',
        element: <InventoryContent />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <RouterProvider router={router} />
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
