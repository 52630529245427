import React from 'react';
import logo from './assets/logo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="p-4">
      <div className="container">
        <div className="mb-4 d-flex flex-column flex-lg-row justify-content-between">
          <div className="footer-logo d-flex justify-content-center align-items-center">
            <img src={logo} alt="" className="rounded-circle" />
          </div>
          <div className="d-flex flex-wrap flex-md-nowrap">
            <div className="mx-3 footer-link col-12 col-md-6">
              <h4 className="">Contact</h4>
              <ul>
                <li className="row">
                  <a
                    href="https://www.google.com/maps/place/OBN+Gadgets/@16.7795767,121.6981194,21z/data=!4m12!1m6!3m5!1s0x3397c81910a0ad7f:0xe5db9ca75b4d67b5!2sHot+Gadgets!8m2!3d14.6378013!4d121.0345225!3m4!1s0x339aab172512390f:0x359326f7e9e2f93b!8m2!3d16.7796524!4d121.6981205"
                    target="_blank"
                    className="col-1 mt-1 mt-lg-2 d-flex"
                  >
                    <i className="fa-solid fa-location-pin"></i>
                  </a>
                  <a
                    className="col-11"
                    href="https://www.google.com/maps/place/OBN+Gadgets/@16.7795767,121.6981194,21z/data=!4m12!1m6!3m5!1s0x3397c81910a0ad7f:0xe5db9ca75b4d67b5!2sHot+Gadgets!8m2!3d14.6378013!4d121.0345225!3m4!1s0x339aab172512390f:0x359326f7e9e2f93b!8m2!3d16.7796524!4d121.6981205"
                    target="_blank"
                  >
                    Reymun 1 Bldg, San Mateo Rd. Alicia, Isabela
                  </a>
                </li>
                <li className="row">
                  <a className="col-1">
                    <i className="fa-solid fa-phone"></i>
                  </a>
                  <a className="col-11">975 811 2700 / 906 458 5017</a>
                </li>
                <li className="row">
                  <a
                    href="https://www.facebook.com/obn.gadgets"
                    target="_blank"
                    className="col-1"
                  >
                    <i className="fa-brands fa-square-facebook"></i>
                  </a>
                  <a
                    className="col-11"
                    href="https://www.facebook.com/obn.gadgets"
                    target="_blank"
                  >
                    fb.com/obn.gadgets
                  </a>
                </li>
                <li className="row">
                  <a
                    href="https://www.m.me/obn.gadgets"
                    target="_blank"
                    className="col-1"
                  >
                    <i className="fa-brands fa-facebook-messenger"></i>
                  </a>
                  <a
                    className="col-11"
                    href="https://www.m.me/obn.gadgets"
                    target="_blank"
                  >
                    m.me/obn.gadgets
                  </a>
                </li>
              </ul>
            </div>
            <div className="mx-3 footer-quick-link col-12 col-md-6">
              <h4>Quick Links</h4>
              <ul>
                <li>
                  <Link className="m-md-0" to="/iphone">
                    iPhone
                  </Link>
                </li>
                <li>
                  <Link className="m-md-0 d-flex" to="/android-accessories">
                    Android & Accessories
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between">
          <div className="my-1 my-lg-0 footer-bottom-items d-flex align-items-center">
            <p>Copyright &copy; 2023 OBN Gadgets</p>
          </div>
          <div className="my-1 my-lg-0 footer-bottom-items d-flex align-items-center">
            <a
              href="https://www.facebook.com/obn.gadgets"
              target="_blank"
              className="mx-2 fs-5"
            >
              <i className="fa-brands fa-square-facebook"></i>
            </a>
            <a
              href="https://www.m.me/obn.gadgets"
              target="_blank"
              className="mx-2 fs-5"
            >
              <i className="fa-brands fa-facebook-messenger"></i>
            </a>
          </div>
          <div className="my-1 my-lg-0 footer-bottom-items d-flex align-items-center">
            <p>Website designed by Marc Aaron</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
