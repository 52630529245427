import React from "react";
import { Link } from "react-router-dom";


function MenuHead({menu}){
    return(
        <>
            <div className="offcanvas-header">
                {/* <img src={logo} alt="" className="rounded-circle" /> */}
                <h5 className="m-0">{menu}</h5>
                <button type="button" data-bs-dismiss="offcanvas" className="btn-close btn-close-white"></button>
            </div>
        </>
    )
}

export default MenuHead;
