import React from 'react';
import logo1 from './assets/1.jpg';
import logo2 from './assets/2.jpg';
import logo3 from './assets/3.jpg';
import CarouselIndicators from './CarouselIndicators';
import CarouselItem from './CarouselItem';

function Carousel() {
  return (
    <div
      id="carouselHome"
      className="carousel slide col-md-8 col-lg-6"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        <CarouselIndicators to="0" active="active" />
        <CarouselIndicators to="1" />
        <CarouselIndicators to="2" />
      </div>
      <div className="carousel-inner w-100">
        <CarouselItem pic={logo1} active="active" />
        <CarouselItem pic={logo2} />
        <CarouselItem pic={logo3} />
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselHome"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselHome"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}

export default Carousel;
