import React from 'react';
import CategoriesItem from './CategoriesItem';

function Categories() {
  return (
    <section className="p-5 category d-flex flex-column align-items-center">
      <h1 className="mb-4">Categories</h1>
      <div className="container d-md-flex d-sm-block">
        <CategoriesItem
          category="iPhone"
          icon="fa-brands fa-apple"
          link="/iphone"
        />
        <CategoriesItem
          category="Android & Accessories"
          icon="fa-brands fa-android"
          link="/android-accessories"
        />
      </div>
    </section>
  );
}

export default Categories;
