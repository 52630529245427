import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../index.css';
import UserContext from './UserContext';


function Receipt() {
  const {Customer, finalOrder, finalPrice,setFinalPrice} = useContext(UserContext)
  

  const handlePrice = () => {
    let ans = 0;
    finalOrder.map((item) => (ans += item.quantity * parseFloat(item.price)));
    setFinalPrice(ans);
  };

  useEffect(()=>{
    handlePrice()
  })
  
  return (
    <>
    <div className=" pt-5"></div>
   
      <div className="container  pt-3 border rounded">
    
        <h2 className="fw-bold pt-5">Your Order is Confirmed!</h2>
    
        {/* <h5>Hi {Customer.map(cust => (cust.fname))},</h5> */}
        <h5>Hi {Customer.fname},</h5>
        <p className="">Your Order is confirmed . . .</p>
        <div className="border-top table-responsive mt-2">
          <table className="table  table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">ORDER DATE</th>
                <th scope="col">ORDER NUMBER</th>
                <th scope="col">PAYMENT</th>
                <th scope="col">ADDRESS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>March 16,2023</td>
                <td>AB123BUYI23H8970C</td>
                <td>Over the Counter</td>
                <td>Cubao,Quezon City</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="border-top mt-2 d-flex flex-column">
        {finalOrder.map((item)=>(
            <div className="container mt-4 d-flex flex-start" key={item.model.concat(item.id)}>
            
   
            <div><img src={item.src} style={{width: '100px'}}/></div>

            <div className="container col-5">
              <div>
                <h5>{item.brand} {item.model}</h5>
                <p>{item.storage} {item.color}</p>
              </div>
            </div>
            <div className="d-flex me-2 col-3 justify-content-between">
              <p className="me-2">Qty: {item.quantity}</p>
              <p className="m">{item.price.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'PHP',
                  })}</p>
            </div>
          </div>))}
        </div>
        {/* <div className="border-top mt-2 d-flex flex-column">
          <div className="container mt-4 d-flex flex-start">
            <div className="container">
              <div>
                <p>Subtotal</p>
                <p>Shipping fee</p>
                <p>Discount</p>
              </div>
            </div>
            <div className="me-2 col-3 d-block">
              <p className="me-2">P60,000 </p>
              <p className="m">P0.00 </p>
              <p className="m">P0.00</p>
            </div>
          </div>
        </div> */}
        <div className="p-3 border-top mt-2 d-flex justify-content-between">
              <h5>Total</h5>
              <p className="me-2">{finalPrice.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'PHP',
              })} </p>
        </div>
        <div className="border-top mt-2 d-flex flex-column">
            <p className='mt-3'> Your order is currently being prepared. We will notify you as soon as it is ready for pickup and kindly request that you come to our store to make the payment for your purchase. </p>
        </div>
        <div>
            <p>Thank you!</p>
            <p>OBN GADGETS</p>
        </div>
        <div className='d-flex flex-column align-items-end'>
        <Link to='/' ><button className='btn btn-warning d-flex  mt-2 px-5 mb-5'>Proceed</button>
        </Link>
        </div>
      </div>
    </>
  );
}

export default Receipt;
