import React from 'react';

function CarouselIndicators({ to, active }) {
  return (
    <>
      <button
        type="button"
        data-bs-target="#products"
        data-bs-slide-to={to}
        className={active}
      ></button>
    </>
  );
}

export default CarouselIndicators;
