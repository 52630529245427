import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UserContext from '../UserContext';
import { useContext } from 'react';
import {Modal, CloseButton} from 'react-bootstrap';



function CheckoutModal() {


  const{setOrderForm, checkoutModal, setcheckoutModal}=useContext(UserContext);

  const navigate = useNavigate();

  return (
    <>

        <Modal show={checkoutModal} onHide={()=>setcheckoutModal(false)}>

            <Modal.Header className="modal-header headx">
              <h1 className="modal-title fs-5" id="checkout_modallabel">
                ONLINE / RESERVATIONS
              </h1>
            <CloseButton variant='white' onClick={()=> setcheckoutModal(false)}/>
            </Modal.Header>
            <Modal.Body>
            <h2 className="text-center pt-3">Online</h2>

            <h5 className="ps-3">

              Service unavailable. Sorry for the inconvenience
            </h5>
            <div className="d-flex flex-row justify-content-center ">


                <button
                  type="button"
                  className="px-4 btn btn-warning"
                  disabled
                  onClick={()=>setOrderForm(true)}
                >
                  Proceed
                </button>


            </div>
            <p></p>
            <p></p>

            <h2 className="text-center">Reservations</h2>

            <h5 className="text-secondary ps-3">
              For reservations please message us first on our Facebook account{' '}
              <a href="https://www.facebook.com/obn.gadgets" target="_blank">
                OBN Gadgets
              </a>{' '}
              or{' '}
              <a href="https://www.m.me/obn.gadgets" target="_blank">
                messenger
              </a>
            </h5>
            <div className="d-flex flex-row justify-content-center ">


                <button
                  type="button"
                  className="mb-3 px-4 btn btn-warning"

                  onClick={()=>{setOrderForm(false); setcheckoutModal(false); navigate('/checkout')}}
                >
                  Proceed
                </button>


            </div>
            </Modal.Body>

            {/* <Modal.Footer className="modal-footer">
              <button
                type="button"
                className=" btn btn-danger"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </Modal.Footer> */}

        </Modal>

    </>
  );
}
export default CheckoutModal;
