import React from 'react';

function CarouselItem({ active, pic }) {
  return (
    <>
      <div className={`carousel-item ${active}`}>
        <img src={pic} className="d-block w-100" alt="..." />
      </div>
    </>
  );
}

export default CarouselItem;
