import React, { useContext, useEffect, useState } from "react";
import AdminContext from "../AdminContext";

const EditModal = (props) => {
  const { editProduct } = useContext(AdminContext);

  const [edit, setEdit] = useState({
    id: props.id,
    image: props.src,
    brand: props.brand,
    model: props.model,
    type: props.type,
    storage: props.storage,
    color: props.color,
    price: props.price,
    stock: props.stock,
  });
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!edit.brand || !edit.model || !edit.price || !edit.stock) {
      return;
    }
    editProduct(edit);
    setEdit({
      image: '',
      brand: '',
      model: '',
      type: '',
      storage: '',
      color: '',
      price: '',
      stock: ''
    });
  };


  const handleEditChange = (event) => {
    const { name, value, files } = event.target;
    
    if (name === 'image' && files && files.length) {
      const file = files[0];
      setEdit({...edit, [name]: URL.createObjectURL(file)});
    } else{

      setEdit({ ...edit, [name]: value });
    }

    
  };
  
  useEffect(() => {
    const { id, brand, model, storage, color, price, stock } = props;
    setEdit({
      id,
      brand,
      model,

      storage,
      color,
      price,
      stock,
    });
  }, [props]);
    return(

      
        <>
            

<div className="modal fade" id="editModal" tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">EDIT ITEM</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body mb">
              <div className="input-group mb-3">
                <span className="input-group-text" id="inputGroup-sizing-default">IMAGE</span>
                <input type="file" className="form-control" name="image" onChange={handleEditChange} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"/>
              </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-default">BRAND</span>
                  <input type="text" className="form-control" name="brand" value={edit.brand} onChange={handleEditChange} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" required/>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-default">MODEL</span>
                  <input type="text" className="form-control" name="model" value={edit.model} onChange={handleEditChange} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" required/>
                </div>
                <div className="input-group mb-3">
                  <label htmlFor="type" className="input-group-text">TYPE</label>
                  <select id="type" name="type" className="form-control" value={edit.type} onChange={handleEditChange}>
                    <option value="" disabled defaultValue="">-----Select an option-----</option>
                    <option value="Apple">Apple</option>
                    <option value="Android">Android</option>
                    <option value="Earphone">Earphone</option>
                    <option value="Case">Phone case</option>
                    <option value="Charger">Charger</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-default">STORAGE</span>
                  <input type="text" className="form-control" name="storage" value={edit.storage} onChange={handleEditChange} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"/>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-default">COLOR</span>
                  <input type="text" className="form-control" name="color" value={edit.color} onChange={handleEditChange} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"/>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-default">PRICE</span>
                  <input type="text" className="form-control" name="price" value={edit.price} onChange={handleEditChange} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"/>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-default">STOCK</span>
                  <input type="text" className="form-control" name="stock" value={edit.stock} onChange={handleEditChange} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"/>
                </div>

      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button 
          type="submit" 
          data-bs-dismiss="modal" 
          className="btn btn-primary" 
          onClick={() => editProduct(edit)} 
          disabled={!edit.brand || !edit.model || !edit.price || !edit.stock}
        >
          Save changes
        </button>
      </div>
</form>
    </div>
  </div>
</div>
        </>
    )
}


export default EditModal;