import React, { useContext, useState } from "react";
import AdminContext from "../AdminContext";

function InventoryModal(){
  const {values,handleChange,handleSubmit} = useContext(AdminContext);
  
  return (
    <>
      <div className="modal fade" id="addModal" tabIndex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">ADD ITEM</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body mb">
              <div className="input-group mb-3">
                <span className="input-group-text" id="inputGroup-sizing-default">IMAGE</span>
                <input type="file" className="form-control" name="image" onChange={handleChange} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"/>
              </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-default">BRAND</span>
                  <input type="text" className="form-control" name="brand" value={values.brand} onChange={handleChange} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" required/>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-default">MODEL</span>
                  <input type="text" className="form-control" name="model" value={values.model} onChange={handleChange} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" required/>
                </div>
                <div className="input-group mb-3">
                  <label htmlFor="type" className="input-group-text">TYPE</label>
                  <select id="type" name="type" className="form-control" value={values.type} onChange={handleChange}>
                    <option value="" disabled defaultValue="">-----Select an option-----</option>
                    <option value="Apple">Apple</option>
                    <option value="Android">Android</option>
                    <option value="Earphone">Earphone</option>
                    <option value="Case">Phone case</option>
                    <option value="Charger">Charger</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-default">STORAGE</span>
                  <input type="text" className="form-control" name="storage" value={values.storage} onChange={handleChange} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"/>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-default">COLOR</span>
                  <input type="text" className="form-control" name="color" value={values.color} onChange={handleChange} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"/>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-default">PRICE</span>
                  <input type="text" className="form-control" name="price" value={values.price} onChange={handleChange} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"/>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="inputGroup-sizing-default">STOCK</span>
                  <input type="text" className="form-control" name="stock" value={values.stock} onChange={handleChange} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"/>
                </div>


                  


      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="submit" data-bs-dismiss="modal" className="btn btn-primary" disabled={!values.brand || !values.model || !values.price || !values.stock || !values.type}>Save changes</button>
      </div>
    </form>
    </div>
  </div>
</div>

</>
)

}

export default InventoryModal;