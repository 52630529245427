import { useEffect, useState } from "react";
import UserContext from "./components/UserContext";
import Home from "./components/Home";
import HowToOrder from "./components/Modal/HowToOrder";
import Cart from "./components/Offcanvas/Cart";
import SideMenu from "./components/Offcanvas/SideMenu";
import './index.css';
import { useNavigate } from 'react-router-dom';
import CheckoutModal from './components/Modal/CheckoutModal';

const iPhoneModelFilterList = [
  {
    id: "iphone-13",
    model: "iPhone 13",
  },
  {
    id: "iphone-12-pro-max",
    model: "iPhone 12 Pro Max",
  },
  {
    id: "iphone-12-pro",
    model: "iPhone 12 Pro",
  },
  {
    id: "iphone-12",
    model: "iPhone 12",
  },
  {
    id: "iphone-11-pro",
    model: "iPhone 11 Pro",
  },
  {
    id: "iphone-11",
    model: "iPhone 11",
  },
  {
    id: "iphone-xr",
    model: "iPhone XR",
  },
  {
    id: "iphone-xs-max",
    model: "iPhone XS Max",
  },
  {
    id: "iphone-xs",
    model: "iPhone XS",
  },
  {
    id: "iphone-x",
    model: "iPhone X",
  },
  {
    id: "iphone-8-plus",
    model: "iPhone 8 Plus",
  },
  {
    id: "iphone-8",
    model: "iPhone 8",
  },
  {
    id: "iphone-7-plus",
    model: "iPhone 7 Plus",
  },
  {
    id: "iphone-7",
    model: "iPhone 7",
  },
]

const AndroidAccessoriesFilterList = [
  {
    id: "android",
    model: "Android",
  },
  {
    id: "earphone",
    model: "Earphone",
  },
  {
    id: "phone-case",
    model: "Case",
  },
  {
    id: "charger",
    model: "Charger",
  },
  {
    id: "other",
    model: "Other",
  },
]


function App() {

  const ENDPOINT = process.env.NODE_ENV === 'production' || process.env.USER === 'ubuntu' ? 'https://grp01api.webpark.tech' : 'http://localhost:3001';


  const [products, setProducts] = useState([]);
  const [orderForm, setOrderForm] = useState(false);
  const [summaryForm, setSummaryForm] = useState(false);
  const [checkoutModal, setcheckoutModal] = useState(false);
  const [howToModal, sethowToModal] = useState(false);
  const [cartOffcanvas, setcartOffcanvas] = useState(false);
  const [filterOffcanvas, setfilterOffcanvas] = useState(false);
  const [sideMenuOffcanvas, setsideMenuOffcanvas] = useState(false);
  const [price, setPrice] = useState(0);
  const [sort, setSort] = useState({value: "desc", label: 'Price'});  
  const [finalPrice, setFinalPrice] = useState(0)
  const [CustomerDetails, setCustomerDetails] = useState({
    fname: '',
    lname: '',
    number: '',
    email: '',
    ship: '',
    city: '',
    region: '',
    postalcode: '',
    notes: '',
  });
  

  const [cartItems, setCartItems] = useState([]);
  const CARTITEM_KEY = 'CARTITEM';
  let IS_INITIAL_SETUP_CART = 0;
  let itemcart;


  const [Filters, setFilters] = useState([]);
  const FILTERLIST_KEY = 'FILTERLIST';
  let IS_INITIAL_SETUP = 0;
  let item;  

  const [Customer, setCustomer] = useState([]);
  const CUSTOMER_KEY = 'CUSTOMER';
  let IS_INITIAL_SETUP_CUST = 0;
  let itemcustomer;
  
  
  const [finalOrder, setFinalOrder] = useState([])
  const ORDERF_KEY = 'ORDERF';
  let IS_INITIAL_SETUP_ORDERF = 0;
  let itemorderf; 



  const navigate = useNavigate();


  const handleSeeMoreButton = (id, brand) => {
    const path = brand.toLowerCase().includes("iphone");

    navigate(`/${path ? 'iphone' : 'android-accessories'}/${id}`, {
      state: {
        data: products.find(items => items.id === id)
      }
    })
    
  }

  const addItemToCart = (itemcart) => {
    if (
      cartItems.find(
        (cartItem) =>
          cartItem.id === itemcart.id && cartItem.model === itemcart.model
      )
    )
      return;
    setCartItems([...cartItems, itemcart]);
  };

  const handleChangeQuantity = (itemcart, delta) => {
    const updatedCartItems = cartItems.map((cartItem) => {
      if (cartItem === itemcart) {
        const newQuantity = cartItem.quantity + delta;
        const quantity = Math.max(1, newQuantity);
        return { ...cartItem, quantity };
      }
      return cartItem;
    });
    setCartItems(updatedCartItems);
  };

  const handleFilters = (filters) => {
    const newFilters = {...Filters, filters}
    setFilters(newFilters.filters)
  }

  const onPlaceOrder = async(values)=>{

    const customer = {
      fname: values.fname,
      lname: values.lname,
      number: values.number,
      email: values.email,
      ship: '' || values.ship,
      city: '' || values.city,
      region: '' || values.region,
      postalcode: '' || values.postalcode,
      notes: values.notes
    }

    await fetch(`${ENDPOINT}/api/customers`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type' : 'application/json'
      },
      body: JSON.stringify(customer),
    }).then((response) =>{

      if(response.status >= 200 && response.status < 400){
        return response.json();
        
      } else{
        throw new Error(`${response.status}: ${response.statusText}`);
      }
      
    })
    .then()
    .catch(err => alert(`Unable to add item: ${err.message}`));
    setFinalOrder([...cartItems])
    setFinalPrice(price)
    setCartItems([])
    }


  const handleSortOrderChange = (option)=> {
      if (option.label === 'Low to High') {
        setSort({ value: 'asc', label: 'Low to High' });
      } else if (option.label === 'High to Low') {
        setSort({ value: 'desc', label: 'High to Low' });
      } else {
        setSort({ value: 'desc', label: 'Price' });
      }
  }

  const sortedProducts = products.sort((a, b) => {
      if (sort.value === "asc") {
        return a.price - b.price;
      } else {
        return b.price - a.price;
      }
  });




  useEffect(() => {
    fetch(`${ENDPOINT}/api/products`, {
      method: 'GET',
      mode: 'cors',
    })
    .then((response) => response.json())
    .then((data) => {
      const newProduct = [...products, ...data];
      setProducts(newProduct);
    })
    .catch(error => console.log('error', error))
  },[])


  useEffect(() => {
  item = localStorage.getItem(FILTERLIST_KEY);
  
  if (!item && item === null) {
    localStorage.setItem(FILTERLIST_KEY, JSON.stringify(Filters));
    item = localStorage.getItem(FILTERLIST_KEY);
  
    IS_INITIAL_SETUP = 1;
    setFilters(JSON.parse(item));
  
  } else {

    IS_INITIAL_SETUP = 1;
  
    const newItems = JSON.parse(item)
  
    setFilters(newItems);
    
  
  }
  }, []);

  useEffect(() => {
    if (!IS_INITIAL_SETUP) {
      localStorage.setItem(FILTERLIST_KEY, JSON.stringify(Filters));
    }
  }, [Filters]);

  useEffect(() => {
    itemorderf = localStorage.getItem(ORDERF_KEY);
    if (!itemorderf && itemorderf === null) {
      localStorage.setItem(ORDERF_KEY, JSON.stringify(finalOrder));
      itemorderf = localStorage.getItem(ORDERF_KEY);
      IS_INITIAL_SETUP_ORDERF = 1;
      setFinalOrder(JSON.parse(itemorderf));
    } else {
      IS_INITIAL_SETUP_ORDERF = 1;
      setFinalOrder(JSON.parse(itemorderf));
    }
    }, []);
  
    useEffect(() => {
      if (!IS_INITIAL_SETUP_ORDERF) {
        localStorage.setItem(ORDERF_KEY, JSON.stringify(finalOrder));
      }
    }, [finalOrder]);

  useEffect(() => {
    itemcustomer = localStorage.getItem(CUSTOMER_KEY);
    if (!itemcustomer && itemcustomer === null) {
      localStorage.setItem(CUSTOMER_KEY, JSON.stringify(Customer));
      itemcustomer = localStorage.getItem(CUSTOMER_KEY);
      IS_INITIAL_SETUP_CUST = 1;
      setCustomer(JSON.parse(itemcustomer));
    } else {
      IS_INITIAL_SETUP_CUST = 1;
      setCustomer(JSON.parse(itemcustomer));
    }
  }, []);
  
  useEffect(() => {
    if (!IS_INITIAL_SETUP_CUST) {
      localStorage.setItem(CUSTOMER_KEY, JSON.stringify(Customer));
    }
  }, [Customer]);

  useEffect(() => {
    itemcart = localStorage.getItem(CARTITEM_KEY);
    if (!itemcart && itemcart === null) {
      localStorage.setItem(CARTITEM_KEY, JSON.stringify(cartItems));
      itemcart = localStorage.getItem(CARTITEM_KEY);
      IS_INITIAL_SETUP_CART = 1;
      setCartItems(JSON.parse(itemcart));
    } else {
      IS_INITIAL_SETUP_CART = 1;
      setCartItems(JSON.parse(itemcart));
    }
  }, []);

  useEffect(() => {
    if (!IS_INITIAL_SETUP_CART) {
      localStorage.setItem(CARTITEM_KEY, JSON.stringify(cartItems));
    }
  }, [cartItems]);

  
  return (
    <>

      <UserContext.Provider value={{summaryForm,CustomerDetails, sideMenuOffcanvas, setsideMenuOffcanvas,
        setCustomerDetails,setSummaryForm, sort, setSort, sortedProducts, filterOffcanvas, setfilterOffcanvas, setFinalPrice,
        orderForm,setOrderForm,addItemToCart, handleSortOrderChange, checkoutModal,setcheckoutModal, setcartOffcanvas,cartOffcanvas,

        handleFilters, products, handleSeeMoreButton, onPlaceOrder, finalOrder, setFinalOrder, finalPrice,
        AndroidAccessoriesFilterList, iPhoneModelFilterList, price, setPrice, Customer, setCustomer,
        Filters, cartItems, howToModal, sethowToModal}}>
        <Home  unit={cartItems.length}/>
        <Cart cartItems={cartItems} setCartItems={setCartItems} handleChangeQuantity={handleChangeQuantity}/>
        <SideMenu/>
        <HowToOrder/>
        <CheckoutModal/>
      </UserContext.Provider>
    </>
  );
}

export default App;
