import React from 'react';
import { Link } from 'react-router-dom';

function CategoriesItem({ icon, category, link }) {
  return (
    <div className="category-item shadow-sm col col-md-6 d-flex p-5 mx-2 my-2 rounded">
      <div className="category-details py-4">
        <h2 className="d-md-flex flex-md-column flex-lg-row align-items-center">
          <i className={icon}></i>&nbsp;{category}
        </h2>
        <div className="hover-btn d-flex justify-content-center">
          <Link to={link} className="btn px-4">
            Buy now
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CategoriesItem;
