import React, { useContext, useEffect, useState } from "react";
import InventoryModal from "./InventoryModal";
import AdminContext from "../AdminContext";
import EditModal from "./EditModal";


function InventoryContent(){
    const {products,deleteProduct} = useContext(AdminContext);
    const [selectedItem, setSelectedItem] = useState([]); // state variable to hold selected item's ID
  
    // function to handle edit button click
    const handleEditClick = (item) => {
        setSelectedItem(item); // set the selected item's ID in state
    }
    
    

    return(
        <>
        <div className="container d-md-flex d-sm-block flex-md-column flex-lg-row pt-5 text-center justify-content-center ">
            <div className="container table-responsive d-flex mt-5 justify-content-center">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                        <th scope="col">ID</th>
                        <th scope="col">IMAGE</th>
                        <th scope="col">BRAND</th>
                        <th scope="col">MODEL</th>
                        <th scope="col">STORAGE</th>
                        <th scope="col">COLOR</th>
                        <th scope="col">PRICE</th>
                        <th scope="col">STOCK</th>
                        <th scope="col">UPDATE/DELETE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((items) => {
                        return(
                            <tr key={`${items.id}`}>
                            <th scope="row">{items.id}</th>
                            <td><img src={items.src} style={{ width: '50px' }} /> </td>
                            <td>{items.brand}</td>
                            <td>{items.model}</td>
                            <td>{items.storage}</td>
                            <th scope="col">{items.color}</th>
                            <td>{items.price.toLocaleString("en-US",{style: 'currency', currency: 'PHP'})}</td>
                            <td>{items.stock}</td>
                            <td>
                            <button className="btn btn-success m-1" data-bs-toggle="modal" data-bs-target="#editModal" onClick={() => handleEditClick(items)}>Edit</button>
                            <button className="btn btn-danger" onClick={() => deleteProduct(items.id)}>Delete</button></td>
                            </tr>
                        )})}    
                    </tbody>
                </table> 
            </div>
        </div>
        <div className=" d-flex flex-row justify-content-center">
            <button type="button" className="btn btn-block btn-primary" data-bs-toggle="modal" data-bs-target="#addModal">
                Add Item
            </button>
        </div>
        <InventoryModal/>
        <EditModal id={selectedItem.id} src={selectedItem.src} brand={selectedItem.brand} model={selectedItem.model} storage={selectedItem.storage} color={selectedItem.color} price={selectedItem.price} stock={selectedItem.stock}/>
        </>
    )
}

export default InventoryContent;
