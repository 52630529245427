import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from './Carousel';

const Hero = () => {
  return (
    <div className="hero d-md-flex d-sm-block">
      <div className="hero-text p-5 col-md-4 col-lg-6 d-flex justify-content-center align-items-center align-items-md-start flex-column text-center text-md-start">
        <h1>Best gadgets you'll ever need.</h1>
        <p>Find the perfect phone and accessories to complete your set up.</p>

        <div>
          <Link className="btn px-5 py-3 rounded" to="/iphone" role="button">
            Shop now
          </Link>
        </div>
      </div>
      <Carousel />
    </div>
  );
};

export default Hero;
