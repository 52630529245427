import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import UserContext from "../UserContext";

function FeaturesItem(props){
    const {addItemToCart, setcartOffcanvas} = useContext(UserContext);
    return (
        <>
            <div className="mb-2 feature-details order-2 order-lg-1 col-5 col-sm-7 col-lg-12" key={props.id}>
                <h1>
                    <span>{props.brand}</span> {props.model}
                </h1>
                <h4>{props.storage} {props.color}</h4>
                <h6 className="d-none d-md-block">
                    <span>Inclusions:</span> Box, Chord, Manual & Sim Ejector
                </h6>
                <h6 className="d-none d-md-block">
                    <span>Freebies:</span> Case & Tempered Glass
                </h6>
                <button 
                className='feature-btn'
                onClick={()=> {addItemToCart(props); setcartOffcanvas(true);}}>
                    Add to cart
                </button>
            </div>
                <div className="feature-img order-1 order-lg-2 col-7 col-sm-5 col-lg-12">

                    <Link to={`/product/${props.id}`}> <img src={props.pic} alt="" /></Link> 

                </div>
        </>
    )
}

export default FeaturesItem;
