import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './LoginPage.css';
import img from './img.png';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();


    

  
  };

  return (
    <div className="login-container">
      {' '}
      {/* Add a class name for the container */}
      <img className="logimg mb-3 rounded-circle" src={img} alt="" />
      <form className="loginform" onSubmit={handleSubmit}>
        <div>
          <label className="ms-3" htmlFor="username">
            Username:{' '}
          </label>
          <input
            className="login_input"
            type="text"
            id="username"
            required={false}
            value={username}
            onChange={handleUsernameChange}
          />
        </div>
        <div>
          <label className="ms-3" htmlFor="password">
            Password:{' '}
          </label>
          <input
            className="login_input"
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            required={true}
          />
        </div>
        <Link to={'/admin/dashboard'}>
          <button className="login_btn" disabled={!username && !password}>
            LOGIN
          </button>
        </Link>
      </form>
    </div>
  );
};

export default Login;
