import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import UserContext from './UserContext';
import '../index.css';

function Summary() {
  const { Customer,onPlaceOrder,price, CustomerDetails, cartItems} = useContext(UserContext);

  return (
    <div className=" container d-md-block d-sm-block flex-md-column flex-lg-row pt-5 ">
      <div className='pt-5'></div>
        <h1 className="summarytext fw-bold pt-3 text-center pb-2 mb-4">Summary</h1>
     
      <div className="container lh-1 p-2 mb-3">
        <p><strong>USER INFORMATION:</strong></p>
        <p className='ps-5'>Name: {Customer.fname} {Customer.lname}</p>
        <p className='ps-5'>Contact: {Customer.number}</p>
        <p className='ps-5'>Email: {Customer.email}</p>
        <p className='ps-5'>Note to Seller: {Customer.notes}</p>
      </div>
      <>
      <h1 className='summarytext text-center p-2'>Order Summary</h1>
        {cartItems.map((item) => (
                <div
                  className="px-3 mt-2 d-flex justify-content-between"
                  key={item.model.concat(item.id)}
                >
 
                    <div className="d-flex flex-column justify-content-center">
                      <h6>
                        {item.brand} {item.model} {item.color} x {item.quantity}
                      </h6>
                    
                  </div>
                  <p>
                        {item.price.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'PHP',
                        })}
                      </p>
                </div>
              ))}
        </>
        <div className='my-3 me-3 d-flex justify-content-end'>Total: {price.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'PHP',
                  })}</div>
      {/* <button
        type="button"
        className="btn btn-success me-3 mb-2"
        onClick={() => setSummaryForm(true)}
      >
        Online Payment
      </button>
      <button
        type="button"
        className="btn btn-success mb-2"
        onClick={() => setSummaryForm(false)}
      >
        Over The Counter
      </button>
      {summaryForm ? (
        <>
          <div className="border-top summarytext text-center mt-2">
            <label htmlFor="onlinebilling" className="form-label mt-3 mb-">
              <h2>Card Information</h2>
            </label>
          </div>
          <div className="d-flex flex-column flex-lg-row ">
            <div className="mt-3 mb-2 pe-lg-2 col-12 col-lg-6 ">
              <input
                type="text"
                id="First Name"
                className="form-control form-control"
                name="First Name"
                placeholder="First Name"
              />
            </div>
            <div className="mt-3 ps-lg-2 col-12 col-lg-6 mb-2">
              <input
                type="text"
                id="region"
                className="form-control form-control"
                name="Last Name"
                placeholder="Last Name"
              />
            </div>
          </div>

          <input
            type="text"
            className="form-control mb-2"
            id="onlinebilling"
            name="onlinebilling"
            placeholder="Address Line 1"
          />
          <input
            type="text"
            className="form-control mb-2"
            id="onlinebilling"
            name="onlinebilling"
            placeholder="Address Line 2"
          />

          <input
            type="text"
            className="form-control mb-2"
            id="onlinebilling"
            name="onlinebilling"
            placeholder="Street Address Line 2"
          />
          <div className="d-flex flex-column flex-lg-row">
            <div className="pe-lg-2 col-12 col-lg-6 mb-2">
              <input
                type="text"
                id="city"
                className="form-control form-control"
                name="city"
                placeholder="City"
              />
            </div>
            <div className="ps-lg-2 col-12 col-lg-6 mb-2">
              <input
                type="text"
                id="region"
                className="form-control form-control"
                name="region"
                placeholder="Region"
              />
            </div>
          </div>
          <div className="d-flex">
            <div className="me-lg-2 col-12 col-lg-6 pe-lg-2 mb-2">
              <input
                type="text"
                id="postalcode"
                className="form-control form-control"
                name="postalcode"
                placeholder="Postal / Zip Code"
              />
            </div>
          </div>
        </>
      ) : null} */}
        
      <div className=" container d-flex flex-column align-items-end border-top border-bottom">
        <Link to='/receipt'><button className='btn btn-warning mt-2 px-5 mb-5' onClick={()=>onPlaceOrder(CustomerDetails)}>Place Order</button>
        </Link>
      </div>
    </div>
  );
}

export default Summary;
