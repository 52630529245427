import React, { useContext } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import UserContext from '../UserContext';
import OffcanvasHeader from './OffcanvasHeader';

function SideMenu() {
  const {sideMenuOffcanvas, setsideMenuOffcanvas, sethowToModal} = useContext(UserContext)
  const navigate = useNavigate();
  return (
    <Offcanvas onHide={()=> setsideMenuOffcanvas(false)} show={sideMenuOffcanvas} className="offcanvas offcanvas-start" id="navigation-bar">
      <OffcanvasHeader menu="Menu" onClose={setsideMenuOffcanvas}/>
      <Offcanvas.Body className="offcanvas-body" id="side-menu">

        <button onClick={()=>{setsideMenuOffcanvas(false); navigate('/')}} 
        className="p-3 btn w-100 d-flex justify-content-start">
          Home
        </button>

        <button
          onClick={()=>{setsideMenuOffcanvas(false); sethowToModal(true)}}
          className="p-3 btn w-100 d-flex justify-content-start"
        >
          How to Order
        </button>
        <button onClick={()=>{navigate('/iphone'); setsideMenuOffcanvas(false)}} className="p-3 btn w-100 d-flex justify-content-start">
          iPhone{' '}
        </button>
        <button onClick={()=>{navigate('/android-accessories'); setsideMenuOffcanvas(false)}} className="p-3 btn w-100 d-flex justify-content-start">
          Android & Accessories{' '}
        </button>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default SideMenu;
