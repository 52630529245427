import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import UserContext from '../UserContext';

function HowToOrder() {
  const {howToModal, sethowToModal} = useContext(UserContext)
  return (
    <>
      <Modal show={howToModal} onHide={()=>sethowToModal(false)}>

          <Modal.Header className="modal-header" closeButton>
            <Modal.Title className="modal-title fs-5" id="how-to-buyLabel">
              How to Order
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body">
            <h3>For pick up:</h3>
            <ol>
              <li>Choose a product.</li>
              <li>Add to cart.</li>
              <li>Proceed to Checkout.</li>
              <li>Complete the details for your order.</li>
              <li>Verify your order to move forward.</li>
              <li>
                Your order number will be sent to your phone number or email.
              </li>
              <li>Provide your order number and make payment at our store.</li>
            </ol>
            <h3>For shipping:</h3>
            <p>
              Message us on our{' '}
              <a href="https://www.facebook.com/obn.gadgets" target="_blank">
                facebook page
              </a>{' '}
              or{' '}
              <a href="https://www.m.me/obn.gadgets" target="_blank">
                messenger
              </a>
              .
            </p>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <button
              type="button"
              className='btn btn-danger'
              onClick={()=>sethowToModal(false)}
            >
              Close
            </button>
          </Modal.Footer>
      
      </Modal>
      </>  
  );
}

export default HowToOrder;
