import React from 'react';
import './AdminPanel.css';

function AdminPanelContent() {
  return (
    <>
      <div className="container d-md-flex d-sm-block flex-md-column flex-lg-row pt-5 text-center  ">
        <div className="d-md-flex d-sm-block flex-row">
          <div className="dash_container container p-5 mt-5 col-md-4 col-lg-6 d-flex justify-content-center align-items-center  flex-column rounded">
            <h1>INVENTORY API IS HERE </h1>
            <button className="btn_inventory mt-2">This is a button</button>
          </div>
        </div>
        <div className="d-md-flex d-sm-block flex-row">
          <div className="dash_container container p-5 mt-5 col-md-4 col-lg-6 d-flex justify-content-center align-items-center  flex-column rounded">
            <h1>THIS IS SALES API</h1>
            {/* TO ADD LINK */}
            <button className="btn_inventory mt-2">This is a button</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminPanelContent;
