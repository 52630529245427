import React, { useState } from 'react';

function FilterItem(props) {
  const myArray = props.data;
  const [Checked, setChecked] = useState(props.isChecked)
  
  const handleToggle = (value) => {
        const currentIndex = Checked.indexOf(value);
        const newChecked = [...Checked];
        if (currentIndex === -1) {
          
            newChecked.push(value)
            
            
        } else {
            
            newChecked.splice(currentIndex, 1)
            
        }
        setChecked(newChecked)
        props.handleFilters(newChecked)
    }
  


    return (
      <>
        {myArray.map((value, index) => (
          <div className="form-check" key={index}>
            <input
              type="checkbox"
              className="form-check-input"
              id={value.id}
              name={value.id}
              checked={props.isChecked.includes(value.model)}
              onChange={() => handleToggle(value.model)}
            />
            <label htmlFor={value.id} className="form-check-label">
              {value.model}
            </label>
          </div>
        ))}
      </>
    );
}

export default FilterItem;
