import { Link, useRouteError } from 'react-router-dom';


const ErrorView = () => {
  const error = useRouteError();
  return (
    <section className="p-4 mb-5 d-flex align-items-center flex-column error container">
                <h1>{error.status}</h1>
                <h3>Woops, looks like this page doesn't exist.</h3>
                <h5>Try going back or go to OBN Gadgets' <Link to='/'>home page.</Link></h5>
    </section>
  );
};

export default ErrorView;
