import React from 'react';
import brandNew from './assets/new.png';
import featuredPhone1 from './assets/iphone-12-pro-graphite.png';
import featuredPhone2 from './assets/iphone-13-midnight.png';
import pic1 from '../assets/iphone/iphone-13-midnight1.png';
import pic2 from '../assets/iphone/iphone-12-pro-graphite-1.png';
import FeaturesItem from './FeaturesItem';

const featureList = [
  {
    id: 1,
    src: pic2,
    pic: featuredPhone1,
    brand: 'Apple',
    model: 'iPhone 12 Pro Max',
    storage: '128GB',
    color: 'Graphite',
    price: 40000,
    quantity: 1,
  },
  {
    id: 5,
    src: pic1,
    pic: featuredPhone2,
    brand: 'Apple',
    model: 'iPhone 13',
    storage: '256GB',
    color: 'Midnight',
    price: 45000,
    quantity: 1,
  },
];

function Features() {
  return (
    <section className="py-5 feature container-fluid d-block d-md-flex flex-wrap">
      {featureList.map((item) => (
        <div
          className="mx-lg-2 my-3 p-4 for-new col-12 col-lg shadow d-flex flex-lg-column  rounded"
          key={item.id}
        >
          <img src={brandNew} className="new" />
          <FeaturesItem
            id={item.id}
            pic={item.pic}
            src={item.src}
            brand={item.brand}
            model={item.model}
            storage={item.storage}
            color={item.color}
            price={item.price}
            quantity={item.quantity}
            
          />
        </div>
      ))}
    </section>
  );
}

export default Features;
