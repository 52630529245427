import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Navbar from './Navbar';

function Home({ unit }) {
  return (
    <>
      <Navbar unit={unit} />
      <>
        <Outlet />
      </>
      <Footer />
    </>
  );
}

export default Home;
