import React, { useEffect, useState } from "react";
import Nav from "../components/Nav";
import { Outlet } from "react-router-dom";
import Menu from "../components/Menu";
import AdminContext from "../AdminContext";
import EditModal from "../Inventory/EditModal";


function AdminPanel(){
  const ENDPOINT = process.env.NODE_ENV === 'production' || process.env.USER === 'ubuntu' ? 'https://grp01api.webpark.tech' : 'http://localhost:3001';
    const [products, setProducts] = useState([]);

//fixed port
    useEffect(() => {
        fetch(`${ENDPOINT}/api/products`, {
          method: 'GET',
          mode: 'cors',
        })
        .then((response) => response.json())
        .then((data) => {
          const newProduct = [...products, ...data];
          setProducts(newProduct);
        })
        .catch(error => console.log('error', error))
      },[])


      const getIndexById = (id) => {
        let index = products.indexOf(products.filter((product) => product.id === id)[0]);
        return index;
      };

      const addProduct = async (values) => {
        const product = {
          // modify
    
          src: '' || values.image,
          brand: values.brand,
          model: values.model,
          type: values.type,
          storage: values.storage,
          color: values.color,
          price: parseInt(values.price),
          stock: parseInt(values.stock)
        }
        // fire an api
        await fetch(`${ENDPOINT}/api/products`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type' : 'application/json'
          },
          body: JSON.stringify(product),
        }).then((response) =>{
    
          if(response.status >= 200 && response.status < 400){
            return response.json();
            
          } else{
            throw new Error(`${response.status}: ${response.statusText}`);
          }
          
        })
        .then(data => {
            const newProduct = [...products]; //copy
            newProduct.push(data);
            setProducts(newProduct);
        })
        .catch(err => alert(`Unable to add item: ${err.message}`));
     };


      const deleteProduct = (id) => {

        fetch(`${ENDPOINT}/${id}`, {
          method: 'DELETE',
          mode: 'cors',
        })
          .then(response => response.json())
          .then(data => {
            if(data.message.includes('successfully deleted')){
              const newProduct = [...products];
              newProduct.splice(getIndexById(id), 1);
              setProducts(newProduct);
            }else{
              alert(`Unable to delete task id=${id}`)
            }
          })
          .catch(error => alert(`Unable to delete task.`))
    
       
      };

      const editProduct = async (values) => {

  
        const product = {    
          src: values['image'] || '',
          brand: values['brand'],
          model: values['model'],
          type: values['type'],
          storage: '' || values['storage'],
          color: values['color'],
          price: parseInt(values['price']),
          stock: parseInt(values['stock'])
        }

        await fetch(`${ENDPOINT}/${values['id']}`, {
          method: 'PUT',
          mode: 'cors',
          headers: {
            'Content-Type' : 'application/json'
          },
          body: JSON.stringify(product),
        }).then((response) => response.json())
        .then((data)=>  console.log(data))
        .catch(error => alert(`Unable to update product`))
    

        setProducts(products.map((product) => {
          if (product.id === values['id']) {
            
            return {
              ...product,
              image: values['image'],
              brand: values['brand'],
              model: values['model'],
              type: values['type'],
              storage: values['storage'],
              color: values['color'],
              price:  parseInt(values['price']),
              stock: parseInt(values['stock'])
            };
          } else {
            return product;
          }
        }));

      };

      

      


      const [values, setValues] = useState({
        image: '',
        brand: '',
        model: '',
        type: '',
        storage: '',
        color: '',
        price: '',
        stock: '',
      });
     
    
    
      const handleChange = (event) => {
        const e = event.target;
        
        if (e.name === 'image' && e.files && e.files.length) {
          const file = e.files[0];
          setValues({...values, [e.name]: URL.createObjectURL(file)});
          
        } else{
    
          setValues({...values, [e.name]: e.value});
        }
        
      };
    
    
    
      const handleSubmit = (e) => {
        e.preventDefault();
        if (!values.brand && !values.model  && !values.price && !values.type && !values.stock) {
          return;
        }
        addProduct(values);
        setValues({
          image: '',
          brand: '',
          model: '',
          type: '',
          storage: '',
          color: '',
          price: '',
          stock: ''
        });
      };

    
    return(
    <>
    <AdminContext.Provider value={{values, products, setProducts, addProduct, deleteProduct, editProduct, handleChange, handleSubmit}}>
        <Nav />
        <Outlet/>
        <Menu/>
        <EditModal/>
    </AdminContext.Provider>
    </>
  );
}

export default AdminPanel;
